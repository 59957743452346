import { Box, SimpleGrid } from '@chakra-ui/react'
import { ReactNode } from 'react'
import './category.css'
import CategoryHeader from './CategoryHeader'
import Row from './Row'

export default function Category() {
    return (
        <>
            <CategoryHeader />
            <Box className="grid-table">
                <SimpleGrid
                    columns={3}
                    textAlign="center"
                    color="gray.600"
                    className="thead"
                >
                    <Label>Nombre</Label>
                    <Label>Destino de preparación</Label>
                    {/* <Label>IP</Label> */}
                    <Label>Acciones</Label>
                </SimpleGrid>
                <Row />
            </Box>
        </>
    )
}

function Label({ children }: { children: ReactNode }) {
    return (
        <Box
            fontSize="sm"
            fontWeight="bold"
            py="3"
            alignSelf={'center'}
            className="th"
        >
            {children}
        </Box>
    )
}
