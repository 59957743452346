import { Button, Flex } from '@chakra-ui/react'
import { render, useRender } from 'redity'
import Check from '../../../components/Icon/Check'
import colors from '../../../config/theme/colors'
import { Keys } from '../constants'
import { storagePage } from '../storage'
import useStorage from '../../../hooks/useStorage'
import ModalPin from '../../../components/ModalPin'
import useSession from '../../../hooks/useSession'

export default function TypeOrder() {
    useRender(Keys.TYPE_ORDER)

    const { session } = useSession()

    const { business_variables } = useStorage()

    const enable_courtesy = business_variables.find(
        v => v.code === '003'
    )?.value

    const enable_admin_pin = business_variables.find(
        v => v.code === '013'
    )?.value

    const type = storagePage.type
    const generate_electronic_receipts =
        storagePage.generate_electronic_receipts == '0' ? false : true

    const propsActive = {
        bg: colors.success,
        color: 'white',
        leftIcon: <Check fill="white" />
    }

    const propsDeactive = {
        colorScheme: 'blackAlpha',
        variant: 'outline'
    }

    function clickHandler(typeOrder: typeof storagePage.type) {
        if (typeOrder === type) {
            storagePage.type = null
        } else {
            storagePage.type = typeOrder
        }
        if (typeOrder === 'free') {
            storagePage['card'] = []
            storagePage['cash'] = []
            storagePage['transfer'] = []
            render(Keys.TABLE_PAYMENTS)
            render(Keys.RESULT_PAY)
            render(Keys.DETAILS)
            render(Keys.MAIN)
        }
        render(Keys.DETAILS)
        render(Keys.PAYMENTS_METHODS)
        render(Keys.TYPE_ORDER)
        render(Keys.MAIN)
    }

    return (
        <Flex>
            <Button
                {...(type === 'ticket' ? propsActive : propsDeactive)}
                onClick={() => clickHandler('ticket')}
                _hover={{}}
                _active={{}}
                hidden={!generate_electronic_receipts}
                fontSize={{ base: 'sm', lg: 'md' }}
            >
                Boleta
            </Button>
            <Button
                ml={2}
                {...(type === 'invoice' ? propsActive : propsDeactive)}
                onClick={() => clickHandler('invoice')}
                _hover={{}}
                _active={{}}
                hidden={!generate_electronic_receipts}
                fontSize={{ base: 'sm', lg: 'md' }}
            >
                Factura
            </Button>
            <ModalPin
                title="Pin de autorización"
                element={click => (
                    <Button
                        ml={2}
                        {...(type === 'free' ? propsActive : propsDeactive)}
                        onClick={
                            enable_admin_pin === '1' && !session?.user.is_vip
                                ? click
                                : () => clickHandler('free')
                        }
                        _hover={{}}
                        _active={{}}
                        hidden={enable_courtesy === '0' ? true : false}
                        fontSize={{ base: 'sm', lg: 'md' }}
                    >
                        Cortesía
                    </Button>
                )}
                onDone={() => clickHandler('free')}
                adminPin
            />
        </Flex>
    )
}
