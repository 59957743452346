import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import ModalFormCategory from '../modals/ModalFormCategory'

export default function CategoryHeader() {
    const { isOpen, onClose, onOpen } = useDisclosure()

    return (
        <Flex mt={4} mb={4} justify="flex-end">
            <ModalFormCategory
                isOpen={isOpen}
                onClose={onClose}
                onDone={() => null}
            />
            <Button
                variant="alo-blue:md-xl"
                onClick={onOpen}
                display={{ base: 'none', md: 'inline' }}
                ml="2"
            >
                Nueva categoría
            </Button>
        </Flex>
    )
}
